import { ButtonHTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { cn } from "@/lib/utils";

interface SearchButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  className?: string;
}

export default function SearchButton({
  className,
  ...props
}: SearchButtonProps) {
  return (
    <button
      className={cn("relative flex-shrink-0", className)}
      type="button"
      {...props}
    >
      <FontAwesomeIcon icon={faSearch} className="size-4 text-black" />
    </button>
  );
}
