import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface InThePressData {
  heading?: {
    text?: string;
    title?: string;
  };
  images?: {
    images_id: {
      alt: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
}

export const fields = [
  "heading.text",
  "heading.title",
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
] as const;

export default function InThePress({ data }: { data?: InThePressData }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => observer.disconnect();
  }, []);

  return (
		<section
			id='in-the-press'
			ref={sectionRef}
			className='w-full py-12 bg-[#F4F4F4]'
		>
			<div className='container mx-auto px-4'>
				<h2
					className='text-4xl md:text-5xl font-freightBig text-center text-secondary-dark mb-8'
					dangerouslySetInnerHTML={sanitizeHTML(
						data?.heading?.title ||
							data?.heading?.text ||
							'Dr. De Silva In the Press'
					)}
				/>
				<div className='relative overflow-hidden max-w-[1080px] mx-auto'>
					<div className='flex whitespace-nowrap'>
						<div
							className={`inline-flex items-center gap-10 ${
								isVisible ? 'animate-scroll will-change-transform' : ''
							}`}
						>
							{data?.images?.map((image, index) => (
								<div key={index} className='shrink-0'>
									<Image
										src={getReadableImageUrl(
											image.images_id.image_file.image.id,
											image.images_id.image_file.image.filename_download
										)}
										alt={image.images_id.alt}
										width={300}
										height={400}
										quality={95}
										className='h-[200px] md:h-[300px] w-auto object-contain'
										loading={'lazy'}
									/>
								</div>
							))}
							{data?.images?.map((image, index) => (
								<div key={`clone-${index}`} className='shrink-0'>
									<Image
										src={getReadableImageUrl(
											image.images_id.image_file.image.id,
											image.images_id.image_file.image.filename_download
										)}
										alt={image.images_id.alt}
										width={300}
										height={400}
										quality={95}
										className='h-[200px] md:h-[300px] w-auto object-contain'
										loading='lazy'
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
