import { sanitizeHTML } from "@/lib/utils";
import Link from "@/components/ui/Link";

export interface HeadingLinkSectionData {
  button: {
    label: string;
    href: string;
  };
}

interface HeadingLinkSectionProps {
  data: HeadingLinkSectionData;
}

export const fields = [
  "button.label",
  "button.href"
] as const;

export default function HeadingLinkSection({ data }: HeadingLinkSectionProps) {
  return (
    <section
      id="HeadingLinkSection"
      className="py-[28px] px-4 bg-black text-white"
    >
      <h2 className="text-[20px] md:text-[26px] font-freightBig text-center">
        <Link href={data?.button?.href || '#'}>
          {data?.button?.label || 'Schedule a Consultation with Dr Julian De Silva'}
        </Link>
      </h2>
    </section>
  );
}
