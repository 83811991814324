import { useState, useEffect } from "react";
import Image from "next/image";
import Link from "@/components/ui/Link";
import { useRouter } from "next/navigation";
import { getReadableImageUrl } from "@/lib/directus";
import SearchBar from "./components/SearchBar";
import Navigation from "./components/Navigation";
import MobileMenu from "./components/MobileMenu";
import SearchButton from "./components/SearchButton";


interface HeaderProps {
  data: HeaderData;
}

export interface HeaderData {
  phone_number: {
    title: string;
  };
  phone_icon: {
    id: string;
    filename_download: string;
  };
  navigation: {
    menu_items: Array<{
      menu_items_id: {
        label: string;
        href: string;
        submenu: {
          label: string;
          href: string;
        }[];
      };
    }>;
  };
  logo_desktop: {
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
    alt: string;
  };
}


export const fields = [
  "phone_number.title",
  "phone_icon.id",
  "phone_icon.filename_disk",
  "navigation.menu_items.menu_items_id.label",
  "navigation.menu_items.menu_items_id.href",
  "navigation.menu_items.menu_items_id.submenu.label",
  "navigation.menu_items.menu_items_id.submenu.href",
  "logo_desktop.image_file.image.id",
  "logo_desktop.image_file.image.filename_download",
  "logo_desktop.alt",
] as const;

export default function Header({ data }: HeaderProps) {
  const router = useRouter();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [, setIsNavVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (isSearchOpen) {
      setIsNavVisible(false);
    } else {
      const timer = setTimeout(() => {
        setIsNavVisible(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isSearchOpen]);

  const handleSearch = (query: string) => {
    router.push(
      `/${process.env.NEXT_PUBLIC_BLOG_SLUG}/search?q=${encodeURIComponent(
        query
      )}`
    );
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false)
  }

  return (
		<header className='sticky top-0 z-[8999] w-full'>
			<div className='w-full bg-white shadow-md'>
				<div className='container mx-auto px-4 flex items-center justify-between gap-2'>
					<a
						href='/'
						className='flex-shrink-0 relative h-12 w-[160px]'
						aria-label='Go to homepage'
					>
						<Image
							src={getReadableImageUrl(
								data.logo_desktop.image_file.image.id,
								data.logo_desktop.image_file.image.filename_download
							)}
							alt={data.logo_desktop.alt || 'Logo'}
							fill
							sizes='160px'
							className='object-contain'
							priority
							quality={100}
						/>
					</a>
					<div className='max-[1400px]:hidden flex items-center gap-8 w-full'>
						{!isSearchOpen ? (
							<>
								<Navigation data={data} onLinkClick={handleLinkClick} />
								<SearchButton
									onClick={() => setIsSearchOpen(true)}
									className='pt-4'
								/>
							</>
						) : (
							<SearchBar
								isOpen={isSearchOpen}
								onClose={() => setIsSearchOpen(false)}
								onSearch={handleSearch}
							/>
						)}
					</div>
					<div className='flex items-center gap-4'>
						{!isSearchOpen && (
							<>
								<Link
									href={`tel:${data.phone_number.title}`}
									className='hover:opacity-70 transition-opacity flex items-center gap-2 text-nowrap max-[1400px]:flex min-[1400px]:hidden'
								>
									<Image
										src={getReadableImageUrl(
											data.phone_icon.id,
											data.phone_icon.filename_disk
										)}
										alt='Phone'
										width={17}
										height={17}
										className='flex-shrink-0 translate-y-[1px]'
										quality={95}
										loading={'lazy'}
									/>
									<span className='max-[768px]:hidden font-aileron'>
										{data.phone_number.title}
									</span>
								</Link>
								<SearchButton
									onClick={() => setIsSearchOpen(true)}
									className='max-[1400px]:block hidden'
								/>
							</>
						)}
						<button
							className='max-[1400px]:block hidden'
							onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
						>
							<div className='w-6 h-0.5 bg-black mb-1.5' />
							<div className='w-6 h-0.5 bg-black mb-1.5' />
							<div className='w-6 h-0.5 bg-black' />
						</button>
					</div>
					<MobileMenu
						isOpen={isMobileMenuOpen}
						data={data}
						onClose={() => setIsMobileMenuOpen(false)}
						onLinkClick={handleLinkClick}
					/>
					{isSearchOpen && (
						<div className='max-[1400px]:block hidden absolute inset-x-0 top-full bg-white z-[99999] shadow-md'>
							<div className='container mx-auto px-4'>
								<SearchBar
									isOpen={isSearchOpen}
									onClose={() => setIsSearchOpen(false)}
									onSearch={handleSearch}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</header>
	)
}
