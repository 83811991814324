import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import type { SearchBarProps } from "../types";

export default function SearchBar({
  isOpen,
  onClose,
  onSearch,
}: SearchBarProps) {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const query = (e.target as HTMLInputElement).value;
      if (query.trim()) {
        onSearch(query.trim());
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="w-full flex items-center gap-4 py-3">
      <input
        type="search"
        placeholder="Search ..."
        className="w-full px-4 py-2 border rounded focus:outline-none focus:border-black"
        autoFocus
        onKeyDown={handleKeyDown}
      />
      <button onClick={onClose} className="flex-shrink-0">
        <FontAwesomeIcon icon={faTimes} className="w-4 h-4 text-black" />
      </button>
    </div>
  );
}
