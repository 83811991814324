import Link from "@/components/ui/Link";
import { usePathname } from "next/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import type { NavigationItemProps } from "../types";

export default function NavigationItem({ item }: NavigationItemProps) {
  const pathname = usePathname() || "";
  const hasSubmenu = item.submenu && item.submenu.length > 0;

  const currentPath = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
  const itemPath = item.href.endsWith("/") ? item.href.slice(0, -1) : item.href;

  const isActive =
    currentPath === itemPath ||
    (hasSubmenu &&
      item.submenu?.some((subItem) => {
        const subItemPath = subItem.href.endsWith("/")
          ? subItem.href.slice(0, -1)
          : subItem.href;
        return currentPath === subItemPath;
      }));

  return (
    <li className="relative group pt-4">
      <Link
        href={item.href}
        className={`font-aileron text-sm py-4 flex items-center gap-1 whitespace-nowrap hover:opacity-70 ${
          isActive ? "text-[#645e5e]" : "text-black"
        }`}
      >
        {item.label}
        {hasSubmenu && (
          <FontAwesomeIcon icon={faChevronDown} className="size-[9px]" />
        )}
      </Link>
      {hasSubmenu && (
        <div className="absolute left-0 mt-0 w-60 bg-white shadow-lg invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 border-t-[3px] border-black">
          {item.submenu?.map((subItem) => {
            const subItemPath = subItem.href.endsWith("/")
              ? subItem.href.slice(0, -1)
              : subItem.href;
            return (
              <Link
                key={subItem.href}
                href={subItem.href}
                className={`block px-5 py-2 text-sm hover:bg-[#f7f7f7] font-aileron ${
                  currentPath === subItemPath ? "text-[#645e5e]" : "text-black"
                }`}
              >
                {subItem.label}
              </Link>
            );
          })}
        </div>
      )}
    </li>
  );
}
