import Image from "next/image";
import Link from "@/components/ui/Link";
import Button from "@/components/ui/button";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML, shimmer, toBase64 } from "@/lib/utils";
import ImagePreloader from "../ImagePreloader";

interface HeroTitleTextCtaData {
  heading: {
    text?: string;
    title?: string;
  };
  image: {
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
    alt: string;
  };
  button: {
    label: string;
    href: string;
  };
  text: {
    text: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  "image.alt",

  "button.label",
  "button.href",
  "text.text",
] as const;

interface HeroTitleTextCtaProps {
  data: HeroTitleTextCtaData;
}

export default function HeroTitleTextCta({ data }: HeroTitleTextCtaProps) {
  const replaceVariables = (text: string) => {
    return text.replace(/{{([\w.]+)}}/g, (match, path) => {
      const value = path
        .split(".")
        .reduce((obj: any, key: string) => obj?.[key], data);
      return value || match;
    });
  };

  if (data.heading?.title) {
    data.heading.title = replaceVariables(data.heading.title);
  }
  if (data.heading?.text) {
    data.heading.text = replaceVariables(data.heading.text);
  }
  if (data.text?.text) {
    data.text.text = replaceVariables(data.text.text);
  }

  return (
    <>
      {/* Preload the hero image */}
      {data?.image?.image_file?.image?.id && data?.image?.image_file?.image?.filename_download && (
        <ImagePreloader
          imageId={data.image.image_file.image.id}
          filename={data.image.image_file.image.filename_download}
        />
      )}
      <section
        id="HeroSection"
        className="relative flex items-center justify-center py-20 px-4 min-h-[300px]"
      >
        <div className="absolute inset-0 z-0">
          <Image
            src={getReadableImageUrl(
              data?.image?.image_file?.image?.id,
              data?.image?.image_file?.image?.filename_download
            )}
            alt={data?.image?.alt}
            fill
            className="object-cover"
            quality={95}
            priority
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(1920, 1080))}`}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-black/80" />
        </div>
        <div className="relative z-10 max-w-[740px] w-[90%] mx-auto text-center">
          {data.heading && (
            <h1
              className="font-freightBig text-5xl text-[36px] md:text-[54px] text-white leading-tight mb-4"
              dangerouslySetInnerHTML={sanitizeHTML(
                data.heading.title || data.heading.text || ""
              )}
            />
          )}

          {data.text && (
            <div
              className="font-aileron text-lg text-white leading-relaxed mb-16 max-w-[455px] mx-auto"
              dangerouslySetInnerHTML={sanitizeHTML(data.text.text)}
            />
          )}

          {data.button && (
            <Button
              asChild
              variant="outline"
              className="text-white border-white hover:bg-[#645e5e]"
            >
              <Link href={data.button.href || ""}>{data.button.label}</Link>
            </Button>
          )}
        </div>
      </section>
    </>
  );
}
