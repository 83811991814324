
interface HeadingSectionData {
  heading: {
    title: string;
  };
  subheading: {
    title: string;
  };
}

interface HeadingSectionProps {
  data: HeadingSectionData;
}

export const fields = [
  'heading.title',
  'subheading.title',
] as const;

  export default function HeadingSection({ data }: HeadingSectionProps) {


  const replaceVariables = (text: string) => {
    return text.replace(/{{([\w.]+)}}/g, (match, path) => {
      const value = path
        .split(".")
        .reduce((obj: any, key: string) => obj?.[key], data);
      return value || match;
    });
  };

  if (data.heading?.title) {
    data.heading.title = replaceVariables(data.heading.title);
  }
  if (data.subheading?.title) {
    data.subheading.title = replaceVariables(data.subheading.title);
  }

  return (
    <section
      id="TestimonialSection"
      className="w-full py-14 md:py-[4%] bg-white"
    >
      <div className="w-[90%] max-w-[820px] mx-auto">
        <div className="flex flex-col items-center">
          {data.subheading?.title && <h4 className="font-bold uppercase text-[13px] tracking-[2px] text-black/60 mb-[18px] md:mb-[18px] text-center">
            {data.subheading?.title}
          </h4>}
          <h2 className="font-freightBig text-[30px] md:text-[35px] text-[#332f2c] leading-[1.1em] text-center mb-[35px] md:mb-[46px] max-w-[630px]">
            {data.heading?.title}
          </h2>
        </div>
      </div>
    </section>
  );
}
