import { getReadableImageUrl } from "@/lib/directus";
import Image from "next/image";
import { sanitizeHTML } from "@/lib/utils";
import { toBase64 } from "@/lib/utils";
import { shimmer } from "@/lib/utils";
import ImagePreloader from "@/components/ImagePreloader";

interface HomeHeroData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  image?: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
  text?: {
    text?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text", 
  "subheading.title",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  "text.text",
] as const;

export default function HomeHero({ data }: { data?: HomeHeroData }) {
  const imageUrl = getReadableImageUrl(
    data?.image?.image_file?.image.id || "", 
    data?.image?.image_file?.image.filename_download || ""
  ) || "";
  
  // Get image ID and filename for preloading
  const imageId = data?.image?.image_file?.image.id || "";
  const imageFilename = data?.image?.image_file?.image.filename_download || "";
  const imageAlt = data?.image?.alt || "Hero Background";

  return (
    <>
      {/* Preload the hero image for faster LCP */}
      {imageId && imageFilename && (
        <ImagePreloader 
          imageId={imageId}
          filename={imageFilename}
          width={1440} // Desktop size
          quality={80}
        />
      )}
      
      <section
        id="home-hero"
        className="relative w-full h-[700px] md:py-0 md:h-[791px]"
      >
        <picture className="absolute inset-0 w-full h-full">
          {/* Mobile source - smaller image for faster loading on mobile */}
          <source 
            media="(max-width: 1024px)" 
            srcSet={`/_next/image?url=${encodeURIComponent(imageUrl)}&w=1440&q=80`}
            type="image/webp"
          />
          
          {/* Desktop source - highest quality */}
          <source 
            srcSet={`/_next/image?url=${encodeURIComponent(imageUrl)}&w=1920&q=90`}
            type="image/webp"
          />
          
          {/* Default Image component remains for fallback */}
          <Image
            src={imageUrl}
            alt={imageAlt}
            fill
            className="object-cover"
            quality={90}
            priority
            sizes="100vw"
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(1920, 1080))}`}
            fetchPriority="high"
          />
        </picture>
        
        <div className="absolute inset-0 container flex items-center">
          <div className="text-white space-y-4 w-full md:w-[70%] lg:w-1/2">
            <h1 
              className="font-freightBig md:text-[53px] text-[32px]"
              dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || 'Dr. Julian De Silva')}
            />
            <span 
              className="block text-2xl pb-10"
              dangerouslySetInnerHTML={sanitizeHTML(data?.subheading?.title || data?.subheading?.text || 'London\'s Leading Facial Plastic Surgery Practice')}
            />
            <div className="space-y-4 text-lg [&>ul>li]:list-disc [&>ul>li]:py-1 [&>ul]:list-inside [&>ul]:pl-4" dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')} />
          </div>
        </div>
      </section>
    </>
  );
}
