"use client"

import Link from "@/components/ui/Link";
import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";
import NavigationItem from "./NavigationItem";
import type { HeaderData } from "../Header";

interface NavigationProps {
  data: HeaderData;
  onLinkClick?: () => void;
}

export default function Navigation({ data, onLinkClick }: NavigationProps) {
  return (
    <nav className="flex-1">
      <ul className="flex items-center justify-end space-x-6">
        {data.navigation.menu_items.map((item) => (
          <NavigationItem
            key={`${item.menu_items_id.href}-${item.menu_items_id.label}`}
            item={item.menu_items_id}
          />
        ))}
        <li className="relative w-[170px] hidden xl:block">
          <Link
            href="/contact-us"
            className="uppercase text-white bg-black text-lg h-20 flex items-center py-[40px] px-[32.5px] absolute top-[-33px] left-0 font-aileron whitespace-nowrap hover:bg-[#645e5e] transition-colors duration-300"
          >
            Contact Us
          </Link>
        </li>
        <li className="pt-4">
          <Link
            href={`tel:${data.phone_number.title}`}
            className="hover:opacity-70 transition-opacity flex items-center gap-2 text-nowrap"
          >
            <Image
              src={getReadableImageUrl(
                data.phone_icon.id,
                data.phone_icon.filename_disk
              )}
              alt="Phone"
              width={12}
              height={12}
              quality={95}
              loading={"lazy"}
            />
            <span className="min-[1600px]:block hidden font-aileron">
              {data.phone_number.title}
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
