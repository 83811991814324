import { useState, useCallback, useEffect } from "react";
import Link from "@/components/ui/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface MobileMenuProps {
  isOpen: boolean;
  data: any;
  onClose: () => void;
  onLinkClick: () => void;
}

export default function MobileMenu({ isOpen, data, onClose, onLinkClick }: MobileMenuProps) {
  const [openSubmenuId, setOpenSubmenuId] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  // Update visibility when isOpen changes
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300); // 300ms delay before hiding
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const getMenuId = (item: { label: string; href: string }) => {
    return `${item.label}-${item.href}`.replace(/\s+/g, "-").toLowerCase();
  };

  const toggleSubmenu = (item: { label: string; href: string }) => {
    const menuId = getMenuId(item);
    setOpenSubmenuId(openSubmenuId === menuId ? null : menuId);
  };

  return (
    <div className={`max-[1400px]:block hidden absolute inset-x-0 top-full bg-white z-50 overflow-y-auto transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
      {data.navigation.menu_items.map((item) => {
        const menuId = getMenuId(item.menu_items_id);
        const hasSubmenu = item.menu_items_id.submenu && item.menu_items_id.submenu.length > 0;
        const hasValidLink = item.menu_items_id.href && item.menu_items_id.href !== '#';
        
        return (
          <div key={menuId} className="border-b border-[rgba(0,0,0,0.03)]">
            <div className={`flex items-center ${hasSubmenu && hasValidLink ? 'justify-between' : ''}`}>
              {hasSubmenu && !hasValidLink ? (
                <button
                  onClick={() => toggleSubmenu(item.menu_items_id)}
                  className="w-full px-4 py-3 text-sm font-aileron text-left flex items-center justify-between"
                >
                  {item.menu_items_id.label}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`w-2 h-2 transform transition-transform ${
                      openSubmenuId === menuId ? "rotate-180" : ""
                    }`}
                  />
                </button>
              ) : (
                <>
                  <Link
                    href={item.menu_items_id.href}
                    onClick={onLinkClick}
                    className="py-3 px-4 text-sm font-aileron"
                  >
                    {item.menu_items_id.label}
                  </Link>
                  {hasSubmenu && (
                    <button
                      onClick={() => toggleSubmenu(item.menu_items_id)}
                      className="w-12 h-12 flex items-center justify-center border-l border-[rgba(0,0,0,0.03)]"
                    >
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`w-2 h-2 transform transition-transform ${
                          openSubmenuId === menuId ? "rotate-180" : ""
                        }`}
                      />
                    </button>
                  )}
                </>
              )}
            </div>
            {item.menu_items_id.submenu && openSubmenuId === menuId && (
              <div className="bg-[rgba(0,0,0,0.03)] pl-8">
                {item.menu_items_id.submenu.map((subItem) => (
                  <Link
                    key={getMenuId(subItem)}
                    href={subItem.href}
                    onClick={onLinkClick}
                    className="block py-2 text-sm hover:bg-white font-aileron"
                  >
                    {subItem.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      })}
      <div className="border-b border-[rgba(0,0,0,0.03)]">
        <div className="flex items-center justify-between px-4">
          <Link 
            href="/contact-us" 
            onClick={onLinkClick}
            className="py-3 text-sm font-aileron"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}
