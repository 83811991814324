import React from 'react';
import Head from 'next/head';
import { getReadableImageUrl } from '@/lib/directus';

interface PreloadImageProps {
  imageId: string;
  filename: string;
  width?: number;
  quality?: number;
}

export default function ImagePreloader({ 
  imageId, 
  filename, 
  width = 3840, 
  quality = 80 
}: PreloadImageProps) {
  
  if (!imageId || !filename) return null;

  const imageUrl = getReadableImageUrl(imageId, filename);
  if (!imageUrl) return null;

  // Create the Next.js optimized image URL format
  const preloadUrl = `/_next/image?url=${encodeURIComponent(imageUrl)}&w=${width}&q=${quality}`;

  return (
    <Head>
      <link
        rel="preload"
        href={preloadUrl}
        as="image"
        type="image/webp"
        fetchPriority="high"
      />
    </Head>
  );
} 